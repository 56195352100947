<template>
  <div
    data-component-name="AppOverlay"
    :class="{ visible }"
    :style="{
      backgroundColor: hexToRGB(COLORS[background], 0.16),
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import hexToRGB from '~/utils/hexToRGB';

const COLORS = {
  'accent-hover': '#F06F02',
  'neutral-hover': '#260B40',
};

export type AppOverlayProps = {
  visible: boolean
  background?: keyof typeof COLORS
};

withDefaults(
  defineProps<AppOverlayProps>(),
  {
    visible: false,
    background: 'accent-hover',
  },
);
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/position";
@import "$/functions/token";

[data-component-name="AppOverlay"] {
  @include fixed-full;
  z-index: 2147483631;

  will-change: backdrop-filter;
  transition: all 0.3s ease;
  pointer-events: none;
  opacity: 0;

  @include flex-center-center;

  &.visible {
    backdrop-filter: blur(12px);
    pointer-events: all;
    opacity: 1;
  }
}
</style>
