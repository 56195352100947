export default function debug(name: string, ...args: any[]) {
  const { public: { env } } = useRuntimeConfig();

  if (env !== 'production') {
    console.log(
      `%c [${env}|${name}]:`,
      'color: cyan; font-weight: bold; font-size: 0.7rem;',
      ...args,
    );
  }
}
