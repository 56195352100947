// utils
import has from 'lodash.has';
import isString from '~/utils/isString';

// types
import type { IpInfo } from '~/types/api-responses';

export default function isValidIpInfo(value: Record<string | number | symbol, unknown>): value is IpInfo {
  const keys: Array<keyof IpInfo> = [
    'iso_code',
    'country',
    'state',
    'ip',
  ];

  return !keys.some(key => !has(value, key) || !isString(value[key]));
}
