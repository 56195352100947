import type { WatchStopHandle } from '@vue/runtime-core';

export default function onRouteChange(callback = (path: string) => {}): WatchStopHandle {
  const route = useRoute();

  return watch(
    () => route.path,
    () => callback(route.path),
  );
}
