import getPathPieces from './getPathPieces';
import getLocaleFromPath from './getLocaleFromPath';
import isLocaleSupported from './isLocaleSupported';

// constants
import { LOCALES } from '~/constants';

export default function removeLocaleFromPath(path: string, onlySupported = false) {
  const locale = getLocaleFromPath(path);

  if (!locale) return path;

  let newPath = '/';
  let locales = [
    ...LOCALES.SUPPORTED_LOCALES,
    ...LOCALES.UNSUPPORTED_LOCALES,
  ];

  if (onlySupported) {
    if (!isLocaleSupported(locale)) return path;

    const app = useNuxtApp();
    locales = app.$i18n.locales.value.map(item => item.code);
  }

  if (locales.includes(locale)) {
    const [, ...pathPieces] = getPathPieces(path);
    newPath += pathPieces.join('/');
  }

  if (newPath.length > 1 && path.endsWith('/')) {
    newPath += '/';
  }

  return newPath;
}
