<script lang="ts">
import { h } from 'vue';

export default {
  render() {
    const { public: { chatlioId: widgetid } } = useRuntimeConfig();

    return h('chatlio-widget', {
      widgetid
    });
  }
};
</script>