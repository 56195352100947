<template>
  <NuxtLink
    data-component-name="AppLogo"
    :to="localePath('/')"
    aria-label="Logo"
  >
    <SvgLogo :class="variant" />
  </NuxtLink>
</template>

<script setup lang="ts">
import { useLocalePath } from '#i18n';

const localePath = useLocalePath();

withDefaults(
  defineProps<{
    variant?: 'purple' | 'orange' | 'white' | 'black' | 'colorful'
  }>(),
  {
    variant: 'purple',
  },
);
</script>

<style scoped lang="scss">
@import "$/variables/color-roles";

[data-component-name="AppLogo"] {
  &:deep(svg) {
    width: 100%;
    height: auto;

    &.purple {
      fill: map-get($accent, 'Secondary', 'Purple-50');
    }

    &.orange {
      fill: map-get($accent, 'Primary', 'Orange-50');
    }

    &.white {
      fill: map-get($neutral, 'White');
    }

    &.black {
      fill: map-get($neutral, 'Gray-10');
    }

    &.colorful {
      path:first-of-type {
        fill: map-get($accent, 'Primary', 'Orange-50');
      }

      path:last-of-type {
        fill: map-get($accent, 'Secondary', 'Purple-50');
      }
    }
  }
}
</style>
