type DataLayerObject = Record<string, any>

type Options = {
  debug?: boolean
}

declare global {
  interface Window {
    dataLayer?: DataLayerObject[]
  }
}

class GTM {
  private options: Options = {
    debug: false,
  };

  constructor(id: string, options?: Options) {
    this.options = { ...options };

    try {
      this.push({ event:'gtm.js', 'gtm.start': new Date().getTime() });

      this.addScript(id);
      this.addNoScript(id);

      this.log('Initialization was successful');
    } catch {
      this.log('Initialization error');
    }
  }

  private get dataLayer() {
    if (!('dataLayer' in window) || !Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }

    return window.dataLayer;
  }

  public push(data: DataLayerObject): void {
    this.log(data);
    this.dataLayer.push(data);
  }

  public trackView(path: string, screenName: string): void {
    this.push({
      event: 'content-view',
      'content-name': path,
      'content-view-name': screenName,
    });
  }

  private addScript(id: string): void {
    const script = document.createElement('script');

    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;

    document.head.prepend(script);
  }

  private addNoScript(id: string): void {
    const iframe = document.createElement('iframe');

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
    iframe.height = '0';
    iframe.width = '0';

    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    const noscript = document.createElement('noscript');
    noscript.append(iframe);

    document.body.prepend(noscript);
  }

  private log(...args: any[]): void {
    if (this.options.debug) {
      console.log('%c[GTM]:', 'color: yellow; font-weight: bold; font-size: 16px;', ...args);
    }
  }
}

export default defineNuxtPlugin(() => {
  if (import.meta.server) return;

  const {
    public: {
      gtm: {
        id,
        debug,
      },
    },
  } = useRuntimeConfig();
  const router = useRouter();
  const gtm = new GTM(id, { debug });

  router.afterEach(to => {
    const name = <string>to.name;
    let url = router.options.history.base;

    if (!url.endsWith('/')) {
      url += '/';
    }

    url += to.fullPath.startsWith('/')
      ? to.fullPath.substring(1)
      : to.fullPath;

    gtm.trackView(url, name);
  });

  return {
    provide: {
      gtm,
    },
  };
});
