import { ofetch } from 'ofetch';

// composables
import { storeToRefs } from 'pinia';

// utils
import isString from '~/utils/isString';
import getUserId from '~/utils/getUserId';

// stores
import { useCommonStore } from '~/stores/common';

type Headers = HeadersInit & {
  Locale: string
  user_id: string
  user_ip: string | null;
};

export default defineNuxtPlugin(() => {
  const nuxt = useNuxtApp();
  const { locale } = nuxt.$i18n;
  const { ipInfo } = storeToRefs(useCommonStore());
  const { public: { apiIpInfo } } = useRuntimeConfig();

  globalThis.$fetch = ofetch.create({
    onRequest({ options, request }) {
      if (
        isString(request) && (
          '_nuxt'.includes(request) ||
          [apiIpInfo].includes(request)
        )
      ) return;

      options.headers ??= {};
      const typedHeaders = <Headers>options.headers;

      typedHeaders.Locale = locale.value || 'en';

      if (import.meta.client) {
        typedHeaders.user_id = getUserId();
        typedHeaders.user_ip = ipInfo.value.ip;
      }
    },
  });
});
