import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45deprecated_45locales_45global from "/app/middleware/01.deprecated-locales.global.ts";
import _02_45trailing_45slash_45global from "/app/middleware/02.trailing-slash.global.ts";
import locale_45global from "/app/middleware/locale.global.ts";
import meta_45tags_45global from "/app/middleware/meta-tags.global.ts";
import track_45first_45visit_45global from "/app/middleware/trackFirstVisit.global.ts";
import user_45journey_45global from "/app/middleware/user-journey.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45deprecated_45locales_45global,
  _02_45trailing_45slash_45global,
  locale_45global,
  meta_45tags_45global,
  track_45first_45visit_45global,
  user_45journey_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}