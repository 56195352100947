import { readonly } from 'vue';
import { defineStore } from 'pinia';

// types
import type { AppOverlayProps } from '~/components/redesign/AppOverlay.vue';

const useUIStore = defineStore('ui', () => {
  const isLoading = ref(false);
  const setLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const overlay = ref<AppOverlayProps>({ visible: false });
  const setOverlay = (props: AppOverlayProps) => {
    overlay.value = { ...overlay.value, ...props };
  };

  const isMobileMenuVisible = ref(false);
  const setMobileMenuVisibility = (value: boolean) => {
    isMobileMenuVisible.value = value;
  };

  const isSearchVisible = ref(false);
  const setSearchVisibility = (value: boolean) => {
    isSearchVisible.value = value;
  };

  const isLanguageSelectorVisible = ref(true);
  const setLanguageSelectorVisibility = (value: boolean) => {
    isLanguageSelectorVisible.value = value;
  };

  const isScrollLocked = computed(() => isLoading.value || overlay.value.visible || isMobileMenuVisible.value);

  return {
    isLoading: readonly(isLoading),
    setLoading,

    overlay: readonly(overlay),
    setOverlay,

    isMobileMenuVisible: readonly(isMobileMenuVisible),
    setMobileMenuVisibility,

    isSearchVisible: readonly(isSearchVisible),
    setSearchVisibility,

    isLanguageSelectorVisible: readonly(isLanguageSelectorVisible),
    setLanguageSelectorVisibility,

    isScrollLocked,
  };
});

export default useUIStore;
