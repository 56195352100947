import { useLocalePath } from '#i18n';
import type { Locale } from '#i18n';

export default function useLocalePathWithSlash(): (value: string, locale?: Locale) => string {
  const localePath = useLocalePath();

  return (value, locale) => {
    const path = localePath(value, locale);

    return path.endsWith('/')
      ? path
      : `${path}/`;
  };
}
