import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jBToDIZpFa from "/app/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import nuxt_plugin_6wEQMY3tee from "/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import chunk_reload_immediate_client_uW4u1tW6oD from "/app/plugins/chunk-reload-immediate.client.ts";
import gql_interceptor_UORmbZjQ17 from "/app/plugins/gql-interceptor.ts";
import gtm_client_OzmBuHLRIb from "/app/plugins/gtm.client.ts";
import interceptor_3QMGQCykd6 from "/app/plugins/interceptor.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import yup_OM8D6DMeq7 from "/app/plugins/yup.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_jBToDIZpFa,
  i18n_yfWm7jX06p,
  nuxt_plugin_6wEQMY3tee,
  chunk_reload_immediate_client_uW4u1tW6oD,
  gql_interceptor_UORmbZjQ17,
  gtm_client_OzmBuHLRIb,
  interceptor_3QMGQCykd6,
  sentry_client_shVUlIjFLk,
  yup_OM8D6DMeq7
]