<template>
  <div class="overlay_loader" :style="isMounted && { display: 'block' }">
    <div class="loader">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script setup lang="ts">
// TODO: fix styles
// TODO: add transition

const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});

onUnmounted(() => {
  isMounted.value = false;
});
</script>

<style scoped lang="scss">
@keyframes rotateAnim {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@-webkit-keyframes rotateAnim {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(90deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes translateAnimTop {
  0% {
    transform: translateY(0px);
    background: #9C2E84;
  }
  25% {
    transform: translateY(0px);
    background: #9C2E84;
  }
  50% {
    transform: translateY(-20px);
    background: none;
  }
  75% {
    transform: translateY(-20px);
    background: none;
  }
  100% {
    transform: translateY(0px);
    background: #9C2E84;
  }
}

@-webkit-keyframes translateAnimTop {
  0% {
    -webkit-transform: translateY(0px);
    background: #6adb9d;
  }
  25% {
    -webkit-transform: translateY(0px);
    background: #9C2E84;
  }
  50% {
    -webkit-transform: translateY(-20px);
    background: none;
  }
  75% {
    -webkit-transform: translateY(-20px);
    background: none;
  }
  100% {
    -webkit-transform: translateY(0px);
    background: #9C2E84;
  }
}

@keyframes translateAnimBottom {
  0% {
    transform: translateY(0px);
    background: #9C2E84;
  }
  25% {
    transform: translateY(0px);
    background: #9C2E84;
  }
  50% {
    transform: translateY(20px);
    background: none;
  }
  75% {
    transform: translateY(20px);
    background: none;
  }
  100% {
    transform: translateY(0px);
    background: #9C2E84;
  }
}

@-webkit-keyframes translateAnimBottom {
  0% {
    -webkit-transform: translateY(0px);
    background: #9C2E84;
  }
  25% {
    -webkit-transform: translateY(0px);
    background: #9C2E84;
  }
  50% {
    -webkit-transform: translateY(20px);
    background: none;
  }
  75% {
    -webkit-transform: translateY(20px);
    background: none;
  }
  100% {
    -webkit-transform: translateY(0px);
    background: #9C2E84;
  }
}

@keyframes translateAnimLeft {
  0% {
    transform: translateX(0px);
    background: #9C2E84;
  }
  25% {
    transform: translateX(0px);
    background: #9C2E84;
  }
  50% {
    transform: translateX(-20px);
    background: none;
  }
  75% {
    transform: translateX(-20px);
    background: none;
  }
  100% {
    transform: translateX(0px);
    background: #9C2E84;
  }
}

@-webkit-keyframes translateAnimLeft {
  0% {
    -webkit-transform: translateX(0px);
    background: #6adb9d;
  }
  25% {
    -webkit-transform: translateX(0px);
    background: #9C2E84;
  }
  50% {
    -webkit-transform: translateX(-20px);
    background: none;
  }
  75% {
    -webkit-transform: translateX(-20px);
    background: none;
  }
  100% {
    -webkit-transform: translateX(0px);
    background: #9C2E84;
  }
}

@keyframes translateAnimRight {
  0% {
    transform: translateX(0px);
    background: #9C2E84;
  }
  25% {
    transform: translateX(0px);
    background: #9C2E84;
  }
  50% {
    transform: translateX(20px);
    background: none;
  }
  75% {
    transform: translateX(20px);
    background: none;
  }
  100% {
    transform: translateX(0px);
    background: #9C2E84;
  }
}

@-webkit-keyframes translateAnimRight {
  0% {
    -webkit-transform: translateX(0px);
    background: #6adb9d;
  }
  25% {
    -webkit-transform: translateX(0px);
    background: #9C2E84;
  }
  50% {
    -webkit-transform: translateX(20px);
    background: none;
  }
  75% {
    -webkit-transform: translateX(20px);
    background: none;
  }
  100% {
    -webkit-transform: translateX(0px);
    background: #9C2E84;
  }
}

.overlay_loader {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(102, 46, 156, 0.4);
  top: 0;
  left: 0;
  z-index: 2147483631 !important;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

/* End Preloder */
.overlay_loader .loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  animation: rotateAnim 2s infinite ease;
  -webkit-animation: rotateAnim 2s infinite ease;
}

.overlay_loader .loader div {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #9C2E84;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  animation: translateAnim 2s infinite ease;
  -webkit-animation: translateAnim 2s infinite ease;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.overlay_loader .loader div:nth-child(1) {
  top: -25px;
  animation-name: translateAnimTop;
  -webkit-animation-name: translateAnimTop;
}

.overlay_loader .loader div:nth-child(2) {
  left: 25px;
  animation-name: translateAnimRight;
  -webkit-animation-name: translateAnimRight;
}

.overlay_loader .loader div:nth-child(3) {
  top: 25px;
  animation-name: translateAnimBottom;
  -webkit-animation-name: translateAnimBottom;
}

.overlay_loader .loader div:nth-child(4) {
  left: -25px;
  animation-name: translateAnimLeft;
  -webkit-animation-name: translateAnimLeft;
}
</style>
