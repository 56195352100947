// utils
import getLocaleFromPath from '~/utils/getLocaleFromPath';
import isLocaleSupported from '~/utils/isLocaleSupported';
import removeLocaleFromPath from '~/utils/removeLocaleFromPath';

// constants
import { TEMPORARILY_MOVED } from '~/constants/status-codes';

export default defineNuxtRouteMiddleware(({ path, hash, query }) => {
  const locale = getLocaleFromPath(path);

  if (locale !== undefined && !isLocaleSupported(locale)) {
    return navigateTo(
      {
        path: removeLocaleFromPath(path),
        hash,
        query
      },
      {
        redirectCode: TEMPORARILY_MOVED,
      },
    );
  }
});
