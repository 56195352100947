import { readonly } from 'vue';
import { defineStore } from 'pinia';

// types
import type { IpInfo } from '~/types/api-responses';

export const useCommonStore = defineStore('common', () => {
  const ipInfo = ref<IpInfo>({
    'ip': '127.0.0.0',
    'iso_code': 'US',
    'country': 'United States',
    'city': 'New Haven',
    'state': 'CT',
    'state_name': 'Connecticut',
    'postal_code': '06510',
    'lat': 41.31,
    'lon': -72.92,
    'timezone': 'America/New_York',
    'continent': 'NA',
    'default': true,
    'currency': 'USD',
  });

  const requestIpInfo = () => {
    if (import.meta.server) {
      console.warn('Please don\'t use ip info request on server side');
      return;
    }

    try {
      return $fetch<IpInfo>(useRuntimeConfig().public.apiIpInfo);
    } catch {
      return;
    }
  };

  const setIpInfo = (info: IpInfo): void => {
    ipInfo.value = info;
  };

  return {
    ipInfo: readonly(ipInfo),
    setIpInfo,
    requestIpInfo,
  };
});
