import { readonly } from 'vue';
import { defineStore } from 'pinia';

// utils
import getESTDate from '~/utils/getESTDate';

// constants
import { MS_IN_MINUTE } from '~/constants/time';
import { PATHS } from '~/constants/nav-items';

const CURRENT_DATE = getESTDate();
const START_DATE = getESTDate(2024, 5, 3, 17, 0, 0);

export const useVeeamOnStore = defineStore('veeamon', () => {
  const route = useRoute();

  const timeUntilVeeamOn = ref(Number(START_DATE) - Number(CURRENT_DATE));

  const isVeeamonStarted = computed(() => timeUntilVeeamOn.value <= MS_IN_MINUTE);
  const isPageMayHaveBanner = computed(() => {
    const [slug] = route.path
      .split('/')
      .filter(Boolean);

    return ![PATHS.HELP.path].some(path => path.includes(slug));
  });

  const timerId = ref<null | NodeJS.Timeout>(null);
  const stopTimer = () => {
    if (timerId.value !== null) {
      clearInterval(timerId.value);
    }
  };
  const intervalHandler = () => {
    if (isVeeamonStarted.value) {
      stopTimer();
      timeUntilVeeamOn.value = 0
    } else {
      timeUntilVeeamOn.value -= MS_IN_MINUTE;
    }
  };

  onMounted(() => {
    if (isVeeamonStarted.value) {
      stopTimer();
      timeUntilVeeamOn.value = 0
    } else {
      timerId.value = setInterval(intervalHandler, MS_IN_MINUTE);
    }
  });
  onUnmounted(stopTimer);

  return {
    timeUntilVeeamOn: readonly(timeUntilVeeamOn),

    isVeeamonStarted,
    isPageMayHaveBanner,
  };
});
