// constants
import { REPORT } from '~/constants/api-endpoints';

const copy = <T extends {}>(obj: T): T => Object
  .getOwnPropertyNames(obj)
  .reduce((acc, key) => {
    acc[key] = obj[key];

    return acc;
  }, {} as T);

export default function useReport(error: Error, url: string) {
  const { public: { env, api_v2: baseURL } } = useRuntimeConfig();

  if (['development', 'production'].includes(env)) {
    const body = { ...copy(error), env, url };

    if (error.cause) {
      body.cause = copy(error.cause);
    }

    $fetch(REPORT, { method: 'POST', baseURL, body })
      .then(() => console.log('[REPORTED]:', error.message))
      .catch(() => console.warn('Error occurred while submitting the error report'));
  }
}
