import getPathPieces from './getPathPieces';

// constants
import { LOCALES } from '~/constants';

export default function hasLocaleInPath(path: string) {
  const [maybeLocale] = getPathPieces(path);

  return [
    ...LOCALES.SUPPORTED_LOCALES,
    ...LOCALES.UNSUPPORTED_LOCALES,
  ].includes(maybeLocale);
}
