// utils
import isMediaFilePath from '~/utils/isMediaFilePath';
import removeLocaleFromPath from '~/utils/removeLocaleFromPath';

// constants
import { MOVED_PERMANENTLY } from '~/constants/status-codes';

export default defineNuxtRouteMiddleware(({ path, hash, query }) => {
  const pathWithoutLocale = removeLocaleFromPath(path, true);

  if (pathWithoutLocale !== '/' && !isMediaFilePath(path) && !path.endsWith('/')) {
    return navigateTo(
      {
        path: path + '/',
        hash,
        query,
      },
      {
        redirectCode: MOVED_PERMANENTLY,
      },
    );
  }
});
