<template>
  <header data-component-name="TheHeader">
    <div class="wrapper">
      <AppLogo />
      <MenuDesktop />

      <div class="right">
        <Buttons.Regular
          fill="none"
          accent="black"
          :aria-label="$t('TheHeader.aria-label.search-btn')"
          @click="showSearch"
        >
          <SvgSearch />
        </Buttons.Regular>

        <LanguageSwitcherDesktop v-if="isLanguageSelectorVisible" />

        <Buttons.Link
          :to="localePathWithSlash(PATHS.HOW_TO_BUY.path)"
          fill="outline"
          accent="orange"
          size="medium"
        >
          {{ $t('How_to_Buy') }}
        </Buttons.Link>

        <Buttons.Link
          :to="localePathWithSlash(PATHS.REQUEST_DEMO.path)"
          fill="solid"
          accent="orange"
          size="medium"
        >
          {{ $t('Request_a_demo') }}
        </Buttons.Link>

        <button
          class="burger"
          type="button"
          :aria-label="$t('TheHeader.aria-label.burger-btn')"
          @click="showMobileMenu"
        >
          <SvgBurger />
        </button>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
// components
import AppLogo from '~/components/redesign/AppLogo.vue';
import Buttons from '~/components/redesign/Buttons';
import MenuDesktop from '~/components/redesign/Menus/MenuDesktop.vue';
import LanguageSwitcherDesktop from '~/components/redesign/LanguageSwitcherDesktop.vue';

// composables
import { storeToRefs } from 'pinia';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// stores
import useUIStore from '~/stores/ui';

// constants
import { PATHS } from '~/constants';

const localePathWithSlash = useLocalePathWithSlash();

const uiStore = useUIStore();

const { isLanguageSelectorVisible } = storeToRefs(uiStore);
const {
  setOverlay,
  setMobileMenuVisibility,
  setSearchVisibility,
} = uiStore;

const showSearch = () => {
  setOverlay({ visible: true, background: 'neutral-hover' });
  setSearchVisibility(true);
};

const showMobileMenu = () => {
  setOverlay({ visible: true });
  setMobileMenuVisibility(true);
};
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/size";
@import "$/mixins/typography";
@import "$/functions/token";
@import "$/variables/shadows";

[data-component-name="TheHeader"] {
  box-shadow: map-get($shadows, 'Elevation 1');
  background-color: token('surface');

  .wrapper {
    @include wrapper(calc(100% - 6rem));
    @include flex-stretch-sb;

    @media screen and (max-width: 1448px) {
      @include wrapper(calc(100% - 4rem));
    }

    @include tablet {
      padding: 0.5625rem 0;
      @include wrapper(calc(100% - 4rem));
    }

    @include mobile {
      padding: 0.61rem 0;
      @include wrapper(calc(100% - 2rem));
    }

    [data-component-name="AppLogo"] {
      @include flex-center-center;

      &:deep(svg) {
        @include mobile {
          @include fixed-width(7rem);
          @include fixed-height(2.27025rem);
        }
      }
    }

    [data-component-name="MenuDesktop"] {
      @include tablet {
        display: none;
      }
    }

    .right {
      @include flex-center-sb;
      gap: 1rem;
      min-height: 100%;

      [data-component-name="LanguageSwitcherDesktop"] {
        @include tablet {
          display: none;
        }
      }

      [data-component-name="Buttons.Link"] {
        @include mobile {
          display: none;
        }
      }

      button.burger {
        display: none;

        @include tablet {
          display: block;
        }
      }
    }
  }
}
</style>
