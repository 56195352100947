import { useNuxtApp } from '#imports'
export default {
  async scrollBehavior(to, from, savedPosition) {
    return new Promise(async (resolve, reject) => {
      const nuxtApp = useNuxtApp()

      if (to.hash) {
        setTimeout(
            () => {
              resolve({ el: to.hash, top: 60, behavior: 'smooth' })
            },
            !from || to.path != from.path ? 300 : 1
        )
      } else if (savedPosition) {
        nuxtApp.hooks.hook('page:finish', async () => {
          await nextTick()
          setTimeout(() => {
            resolve({ ...savedPosition, behavior: 'smooth' })
          }, 500)
        })
      } else {
        nuxtApp.hooks.hook('page:finish', async () => {
          await nextTick()
          setTimeout(() => {
            resolve({ top: 0, behavior: 'smooth' })
          }, 130)
        })
      }
    })
  },
}
