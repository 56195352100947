import Cookie from '~/services/Cookie';

export default defineNuxtRouteMiddleware((to, from) => {
  // todo: test
  if (process.client) {
    readAndSetCookies();
  }

  // wp-content/themes/ofirst/template-parts/other/other-of-functions.php
  // console.log(to, from)
})

const ONE_DAY_MILLISECONDS: number = 86400 * 1000;

function readAndSetCookies(): void {
  const lifetime: number = ONE_DAY_MILLISECONDS * 365;

  // todo: refactor
  const utmParams: string[] = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid', '_ga'];
  utmParams.forEach(param => setMarketingCookie(param, lifetime));
}

function setMarketingCookie(name: string, lifetime: number): void {
  const value: string | null = new URLSearchParams(window.location.search).get(name);
  // console.log(name, value);

  if (value) {
    Cookie.set(`c_of_${name}`, value, {'max-age': lifetime});

    // document.cookie = `c_of_${name}=${value};expires=${new Date(Date.now() + lifetime).toUTCString()};path=/`;
  }
}

// ?utm_source=123&utm_medium=123&utm_campaign=123&utm_content=123&utm_term=123
