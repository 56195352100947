<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>

  <AppOverlay id="overlay" v-bind="overlay" />
  <AppLoader v-if="isLoading" />

  <ClientOnly>
    <TheChatlio />
  </ClientOnly>
</template>

<script setup lang="ts">
// components
import AppLoader from '~/components/redesign/AppLoader.vue';
import AppOverlay from '~/components/redesign/AppOverlay.vue';
import TheChatlio from '~/components/redesign/TheChatlio.vue'

// composable
import { useI18n } from '#i18n';
import { storeToRefs } from 'pinia';
import { useAbsoluteUrl } from '~/composables/absoluteUrl';

// utils
import isObject from '~/utils/isObject';
import isValidJSON from '~/utils/isValidJSON';
import isValidIpInfo from '~/utils/isValidIpInfo';
import moment from 'moment';

// store
import useUIStore from '~/stores/ui';
import { useCommonStore } from '~/stores/common';

// services
import Cookie from '~/services/Cookie';

// types
import type { IpInfo } from '~/types/api-responses';

const { t } = useI18n();

const route = useRoute();
const { locale } = useI18n();
const { getAbsoluteUrl } = useAbsoluteUrl();

const uiStore = useUIStore();
const { isLoading, overlay, isScrollLocked } = storeToRefs(uiStore);

const commonStore = useCommonStore();
const { requestIpInfo, setIpInfo } = commonStore;

const getIpInfoFromCookie = (): IpInfo | undefined => {
  const pureIpInfoFromCookie = Cookie.get('ip_info');

  if (!isValidJSON(pureIpInfoFromCookie)) return;

  const parsedIpInfoFromCookie = JSON.parse(pureIpInfoFromCookie);

  if (!isObject(parsedIpInfoFromCookie) || !isValidIpInfo(parsedIpInfoFromCookie)) return;

  return parsedIpInfoFromCookie;
};

onMounted(async () => {
  const ipInfoFromCookie = getIpInfoFromCookie();

  if (ipInfoFromCookie) setIpInfo(ipInfoFromCookie);
  else {
    try {
      const response = await requestIpInfo();

      if (response) {
        setIpInfo(response);
        Cookie.set('ip_info', JSON.stringify(response), {
          expires: moment().add(7, 'days').toDate(),
        });
      }
    } catch {
      void clearError();
    }
  }
});

watchEffect(() => {
  if (import.meta.server) return;

  document.body.classList[
    isScrollLocked.value ? 'add' : 'remove'
  ]('no-scroll');
});

useHead({
  link: [{ rel: 'canonical', href: getAbsoluteUrl(route.path) }],
  title: () => t('index.seo.title'),
  meta: [
    { name: 'description', content: () => t('index.seo.description') },
    { property: 'og:image', content: 'https://objectfirst.nyc3.digitaloceanspaces.com/Metadata/OG_Banner_General_360x169.png' }
  ],
  htmlAttrs: {
    lang: locale,
  },
});

useSchemaOrg([
  defineWebSite({ image: 'https://objectfirst.nyc3.digitaloceanspaces.com/Metadata/OG_Banner_General_360x169.png' }),
  defineImage({
    url: 'https://objectfirst.b-cdn.net/uploads/logos/logo-white.svg',
    width: 103,
    height: 51,
    caption: "Object First"
  })
]);
</script>

<style scoped lang="scss">
[data-component-name="AppOverlay"] {
  padding: 1rem;
}
</style>
