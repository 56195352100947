import * as yup from 'yup';
import freeEmails from '~/data/freeemails.json';
import { useI18n } from '#i18n';

export default defineNuxtPlugin(() => {
  yup.addMethod(yup.string, 'companyEmail', function (errorMessage) {
    const { t } = useI18n();
    
    return this.test(errorMessage, function (value) {
      if (!value) return;

      let isValid = true;

      const lowerCaseValue = value.toLowerCase();
      const [_, domain] = lowerCaseValue.split('@');

      if (domain && freeEmails.includes(domain)) {
        isValid = false;
      }

      return isValid || this.createError({
        path: this.path,
        message: errorMessage || t('Validations.Invalid_corporate_email'), 
      });
    });
  });

  yup.addMethod(yup.string, 'veeamEmail', function (errorMessage) {
    return this.test(errorMessage, function (value) {
      if (typeof value !== 'string' || !value.length) return;

      let isValid = true;

      const index = value.indexOf('@');

      if (index > -1) {
        const [domain] = value.substring(index + 1).split('.');

        if (domain !== 'veeam') {
          isValid = false;
        }
      }

      return isValid || this.createError({
        path: this.path,
        message: errorMessage || 'For Veeam members only. Please, use the corporate Veeam email.',
      });
    });
  });
});
