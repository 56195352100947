import Base from './Base.vue';
import Link from "./Link.vue";
import Regular from "./Regular.vue";
import ReadMore from './ReadMore.vue';
import Form from './Form.vue';

export default {
  Base,
  Link,
  Regular,
  ReadMore,
  Form,
}
