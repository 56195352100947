import Cookie from '~/services/Cookie';
import { ACTIVITY } from '~/constants/api-endpoints';

export type RequestBody = {
  url: string
  start: number
  stop: number
  duration: number
  cookie: Partial<ReturnType<typeof Cookie.getCookieArray>>
}

const COOKIE_KEY = 'activity';

export default class Activity {
  public static write(url: string) {
    const activity: Pick<RequestBody, 'url' | 'start'> = {
      url,
      start: Date.now(),
    };

    Cookie.set(COOKIE_KEY, JSON.stringify(activity));
  }

  public static dispatch() {
    const pureActivityFromCookie = Cookie.get(COOKIE_KEY);

    if (typeof pureActivityFromCookie !== 'string') return;

    try {
      const parsedActivityFromCookie: never | unknown | null = JSON.parse(pureActivityFromCookie);

      if (
        parsedActivityFromCookie !== null &&
        typeof parsedActivityFromCookie === 'object' &&

        'start' in parsedActivityFromCookie &&
        typeof parsedActivityFromCookie.start === 'number' &&

        'url' in parsedActivityFromCookie &&
        typeof parsedActivityFromCookie.url === 'string'
      ) {

        const { url, start } = parsedActivityFromCookie;
        const stop = Date.now();

        const activity: RequestBody = {
          url,
          start,
          stop,
          duration: (stop - start) / 1000,
          cookie: Cookie.getCookieArray(),
        };

        void Activity.send(activity);
      }
    } catch {
      debug('Activity.dispatch', 'invalid activity');
    } finally {
      Cookie.delete(COOKIE_KEY);
    }
  }

  public static async send(body: RequestBody) {
    const { public: { api_app: baseURL } } = useRuntimeConfig();

    try {
      await $fetch(ACTIVITY, {
        method: 'POST',
        baseURL,
        body,
      })
    } catch (error) {
      debug('Activity.send', error);
    }
  }
}
