// https://emailregex.com
export const EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const YOUTUBE_VIDEO_ID = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
export const UUID_V4 = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export const SHORT_DATE = /(\d{4})-(\d{2})-(\d{2})/;
export const FULL_DATE = new RegExp(SHORT_DATE.source + '\\s(\\d{2}):(\\d{2}):(\\d{2})');

export const KEY_CODE = /^[0-9a-zA-Z]{5}$/;
